import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Paper, Box, Typography, Card, CardContent } from '@material-ui/core'
import classNames from 'classnames'
import Masonry from 'react-masonry-css'
import useStyles from './style'
import UserIcon from '../../../static/images/userIcon.svg'

const datas = [
  {
    name: 'Jean Pierre Robert Niro',
    date: '03 Sep 2020',
    message:
      'To Whom It May Concern: I never have any complaints when it comes to my barber, Heru. I am always satisfied as to all of the above. Thank you, J-PRN.',
  },
  { name: 'Simon Pang', date: '10 Jan 2020', message: 'Relaxing Experience.' },
  {
    name: 'Heriyanto Wijaya',
    date: '18 Jun 2020',
    message:
      'Like to do hair cut at Marco’s GI especially with ur hairstylist Pak Boy.',
  },
  {
    name: 'Bobby',
    date: '20 Jan 2020',
    message: 'Great service, relax and skillful barber.',
  },
  {
    name: 'Ken',
    date: '12 Mar 2020',
    message:
      'Very short wait time! The barber cut my hair as I requested which is hard to find these days',
    hide: true,
  },
  {
    name: 'Setiawan',
    date: '08 Mar 2020',
    message:
      'Loyal customer for their great customer service, no wait time and the best haircut ... for 3 years and counting now.',
    hide: true,
  },

  {
    name: 'Tat Nhan',
    date: '03 May 2019',
    message: 'Awesome experience. Will keep coming back.',
    hide: true,
  },
  {
    name: 'Welih',
    date: '05 May 2019',
    message:
      '1st experience. Heru the cutter is very prof. Do the work with a right procedure & precision. Such a great feeling being treated in a good hand.',
    hide: true,
  },
  {
    name: 'Raj Amarnani',
    date: '04 Jul 2019',
    message:
      'Started as per booking time, neat haircut as usual, good timing. no complains. Perfect.',
    hide: true,
  },
]

const Testimonials = props => {
  const classes = useStyles()
  const theme = useTheme()
  const { heading, dataTesti } = props
  const breakpointCols = {
    default: 4,
    [theme.breakpoints.values.xl]: 3,
    [theme.breakpoints.values.lg]: 3,
    [theme.breakpoints.values.md]: 3,
    [theme.breakpoints.values.sm]: 1,
    [theme.breakpoints.values.xs]: 1,
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Box>
        <Box textAlign="center" mb="8px">
          <Typography variant="overline" className={classes.overline}>
            {heading.title}
          </Typography>
        </Box>
        <Box
          textAlign="center"
          mb="26px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <Typography variant="h4" className={classes.heading}>
            {heading.head}
          </Typography>
        </Box>

        <Box mb="80px">
          <Typography variant="body2" className={classes.caption}>
            {heading.desc}
          </Typography>
        </Box>
      </Box>
      <Masonry
        breakpointCols={breakpointCols}
        className={classes.masonryGrid}
        columnClassName={classes.masonryColumn}
      >
        {/* {items} */}
        {dataTesti.map((data, index) => (
          <Card
            key={`testi-${index}`}
            className={classNames(
              classes.cardWrapper,
              data.hide ? classes.hideSm : undefined
            )}
          >
            <CardContent>
              <Typography
                variant="body2"
                component="p"
                className={classes.message}
              >
                {data.message}
              </Typography>
            </CardContent>

            <Box
              display="flex"
              justifyContent="space-between"
              padding="0 20px"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="body2" className={classes.userName}>
                  {data.name}
                </Typography>
                <Typography variant="body2" className={classes.date}>
                  {data.date}
                </Typography>
              </Box>
              <Box>
                <img src={UserIcon} />
              </Box>
            </Box>
          </Card>
        ))}
      </Masonry>
    </Paper>
  )
}

export default Testimonials
