import React from 'react'
import { Paper, Grid, Typography, Box } from '@material-ui/core'
import useStyles from './style'
import SketchImg from '../../../static/images/sketch-1.png'

const About = props => {
  const classes = useStyles()
  const { title, heading, deskripsi, gambar } = props
  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container spacing={1} className={classes.gridWrapper}>
        {/* Left Side */}
        <Grid item md={6} sm={6} xs={12}>
          <Box className={classes.imgWrapper} display="flex">
            <img
              src={gambar} //lupa di cms
              alt="Sketch 1"
              className={classes.sketchImage}
            />
          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item md={6} sm={6} xs={12}>
          <Box width="100%" flexDirection="column" className={classes.gridItem}>
            <Box mb="16px">
              <Typography variant="overline" className={classes.overline}>
                {title}
              </Typography>
            </Box>
            <Box mb="32px">
              <Typography
                variant="h4"
                className={classes.heading}
                dangerouslySetInnerHTML={{ __html: heading }}
              ></Typography>
            </Box>
            <Box>
              <Box mb="32px">
                <div
                  dangerouslySetInnerHTML={{ __html: deskripsi }}
                  className={classes.body}
                />

                {/* <Typography variant="body2" className={classes.body} dangerouslySetInnerHTML={ {__html: deskripsi} } >
                  </Typography> */}
              </Box>
              {/* <Typography variant="body2" className={classes.body}>
                  Our services are of a premium quality with no compromises when
                  it comes to the needs of our clients. From our personalized
                  barbershop services to the quality of delivering the services,
                  we provide the guidance, confidence , knowledge, tools, and
                  inspiration every man needs to put his best face forward.
                </Typography> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default About
