import { makeStyles } from '@material-ui/core/styles'
const sectionPadding = '9.375rem 7.5rem'
const mainPadding = '4.688rem 0.625rem'
const smallFont = 14
const useStyles = makeStyles(theme => ({
  // root: {
  //   // background: "#FFF",
  //   padding: "150px 5px",
  //   minHeight: "100vh",
  //   display: "flex",

  //   // justifyContent: "center",
  //   // alignItems: "center",
  //   // [theme.breakpoints.down("xs")]: {
  //   //   height: "auto",
  //   // },
  // },

  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(3, 0, 2, 0),
    background: '#FFF',
    padding: sectionPadding,
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: mainPadding,
    },
  },
  cardWrapper: {
    minWidth: 275,
    minHeight: 160,
    background: '#F7F7F7',
    marginBottom: '20px',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  hideSm: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paper: {
    marginBottom: theme.spacing(4),
  },
  message: {
    fontSize: 16,
    color: '#000',
    opacity: '0.8',
    lineHeight: '24px',
  },
  userName: {
    fontWeight: 600,
    fontSize: 16,
  },
  date: {
    color: '#000',
    opacity: '0.8',
  },
  /* Typography */
  overline: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 1.5,
    fontSize: smallFont,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  heading: {
    fontFamily: 'roosevelt02',
    fontSize: 48,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
  },
  caption: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '24px',
    color: '#000',
    opacity: '0.8',
  },
  /* Grid */
  masonryGrid: {
    display: 'flex',
    marginLeft: theme.spacing(-4),
    // width: "inherit",
  },
  masonryColumn: {
    paddingLeft: theme.spacing(4),
    backgroundClip: 'padding-box',
  },
}))

export default useStyles
