import React from 'react'
import classNames from 'classnames'
import { Paper, Grid, Typography, Box, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import InstagramIcon from '../../../static/images/instagramIcon.svg'
import Gallery1 from '../../../static/images/gallery-1.png'
import Gallery2 from '../../../static/images/gallery-2.png'
import Gallery3 from '../../../static/images/gallery-3.png'
import Gallery4 from '../../../static/images/gallery-4.png'
import Gallery5 from '../../../static/images/gallery-5.png'
import Gallery6 from '../../../static/images/gallery-6.png'
import useStyles from './style'

const Gallery = props => {
  const classes = useStyles()
  const { title, heading, deskripsi, gambar, footer } = props
  return (
    <Paper className={classes.root}>
      <Box display="flex" flexDirection="column">
        <Box className={classes.textWrapper}>
          <Box textAlign="center" mb="16px">
            <Typography variant="overline" className={classes.overline}>
              {title}
            </Typography>
          </Box>
          <Box
            textAlign="center"
            mb="32px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <Typography
              variant="h4"
              className={classes.heading}
              dangerouslySetInnerHTML={{ __html: heading }}
            ></Typography>
          </Box>
          <Box
            textAlign="center"
            display="flex"
            justifyContent="center"
            marginBottom="60px"
          >
            <Typography
              variant="body2"
              className={classes.body}
              dangerouslySetInnerHTML={{ __html: deskripsi }}
            ></Typography>
          </Box>
        </Box>
        {/* Gallery Photo */}
        <Box className={classes.galleryPhotoWrapper} mb="60px">
          <Grid container className={classes.gridWrapper} wrap="nowrap">
            {/* 3 Pertama */}
            <Grid
              sm={3}
              className={classNames(classes.gridItem, classes.singleGrid)}
            >
              <img
                src={gambar[0]}
                alt="gallery-item"
                className={classes.longImage}
              />
            </Grid>

            <Grid
              sm={3}
              className={classNames(classes.gridItem, classes.nestedGrid)}
            >
              <Box>
                <img
                  src={gambar[1]}
                  alt="gallery-item"
                  className={classes.shortImageSquare}
                />
              </Box>
              <Box>
                <img src={gambar[2]} alt="gallery-item" />
              </Box>
            </Grid>

            {/* 3 Terakhir */}
            <Grid
              sm={3}
              className={classNames(
                classes.gridItem,
                classes.singleGrid,
                classes.mobileHide
              )}
            >
              <img
                src={gambar[3]}
                alt="gallery-item"
                className={classes.longImage}
              />
            </Grid>

            <Grid
              sm={3}
              className={classNames(
                classes.gridItem,
                classes.nestedGrid,
                classes.mobileHide
              )}
            >
              <Box>
                <img
                  src={gambar[4]}
                  alt="gallery-item"
                  className={classes.shortImageSquare}
                />
              </Box>
              <Box>
                <img src={gambar[5]} alt="gallery-item" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* Button Area */}
        <Box>
          <Box textAlign="center" mb="28px">
            <Typography variant="h6" className={classes.followUsHeading}>
              {footer.title}
            </Typography>
          </Box>
          <Box textAlign="center">
            <Button
              href={footer.link}
              variant="contained"
              color="primary"
              className={classes.instagramButton}
              startIcon={<img src={InstagramIcon} />}
              disableElevation
            >
              {footer.text}
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default Gallery
