import { graphql, useStaticQuery } from 'gatsby'

export const usePopupModal = () => {
  const { datoCmsPopup: popup } = useStaticQuery(graphql`
    query getPopupImage {
      datoCmsPopup {
        enable
        id
        image {
          url
          alt
        }
        link
      }
    }
  `)

  return popup
}
