import { makeStyles } from '@material-ui/core/styles'

const sectionPadding = '9.375rem 7.5rem'
const mainPadding = '4.688rem 0.625rem'
const smallFont = 14
const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#000',
    padding: sectionPadding,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: mainPadding,
    },
    color: '#FFF',
  },
  /* Typography */
  overline: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 1.5,
    fontSize: smallFont,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  heading: {
    fontFamily: 'roosevelt02',
    fontSize: 48,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
  },
  body: {
    maxWidth: '24rem',
    [theme.breakpoints.up('md')]: {
      width: 485,
    },
    lineHeight: '1.5rem',
    opacity: '80%',
  },
  /* Gallery Grid Setting */
  gridWrapper: {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      height: 'auto',
      padding: mainPadding,
    },
  },
  gridItem: {
    padding: 10,
  },
  singleGrid: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  nestedGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  shortImageSquare: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  longImage: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 15,
    },
    height: '100%',
    display: 'block',
    margin: 'auto',
  },
  mobileHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  /* Button */
  instagramButton: {
    margin: theme.spacing(1),
    width: 214,
    height: 48,
    borderRadius: 0,
    color: '#FFF',
    textTransform: 'lowercase',
  },
  followUsHeading: {
    fontWeight: 'bold',
    fontSize: '1.75rem',
  },
  paperWrapper: {
    background: 'transparent',
    color: '#FFF',
  },
}))

export default useStyles
